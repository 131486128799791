// モーダルの開閉のみ
$(function() {
  const modal = $('#js-mypage_menu_modal');
  const modalOpen = $('#js-mypage_menu_modal_open');

  $(modalOpen).on('click', function() {
    $(modal).toggleClass('l-header_wrap_links_mypage_modal_open');
  });

  $(document).on('click', function(e) {
    if ($('#js-mypage_menu_modal').hasClass('l-header_wrap_links_mypage_modal_open')) {
      if(!$(e.target).closest('#js-mypage_menu_modal').length && !$(e.target).closest('#js-mypage_menu_modal_open').length) {
        closeModal();
      }
    }
  });
});

function closeModal() {
  const modal = $('#js-mypage_menu_modal');
  $(modal).removeClass('l-header_wrap_links_mypage_modal_open');
}
