// 
// この処理を実行するページでは、以下のERBの読み込みが必要
// <%= render 'shared/expandedGalleryModal' %>
// 

$(function() {
  const gallery = document.querySelectorAll('.js-expandableGallery');
  const modal = document.querySelector('.js-expandedGalleryModal');
  const imageElement = document.querySelector('.js-expandedGalleryImage');
  const closeBtn = document.querySelector('.js-expandedGalleryModalClose');
  const modalBox = document.querySelector('.js-expandedGalleryModalBox');

  $(document).on("click", ".js-expandableGallery", function() {
    $(modal).addClass('c-expandedGalleryModal_open');
    const imageUrl = $(this).find('.c-expandableGallery_image').attr('src');
    $(imageElement).attr('src', imageUrl);
  });

  $(document).on("click", ".js-expandedGalleryModalClose", function() {
    closeModal();
  });

  // モーダルオープン時にモーダル外をクリックした場合にモーダルを閉じる
  $(modal).on('click', function(e) {
    if(!$(e.target).closest($(modalBox)).length) {
      closeModal();
    }
 });

  function closeModal() {
    const modal = document.querySelector('.js-expandedGalleryModal');
    $(modal).removeClass('c-expandedGalleryModal_open');
  }
});
