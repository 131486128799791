let galleryCheckedIds = [];
const MAX_NUMBER_OF_GALLERIES = 6;

// モーダルの開閉のみ
$(function() {
  const modal = document.querySelector('.js-modal');
  const modalOpen = document.querySelector('.js-modal_open');
  const modalCloseBox = document.querySelector('.js-modal_closebox');
  const modalClose = document.querySelector('.js-modal_close');
  
  // モーダルボタンオープンをクリックした時の処理
  $(modalOpen).on('click', function() {
    $(modal).addClass('c-selectGalleryModal_modal_open');

    const galleryIds = $('#js-hidden_field_gallery_ids').val();

    // hidden_field の galleryIds（String）が空でなければ、配列を作成
    galleryCheckedIds = galleryIds ? galleryIds.split(',').map(Number) : [];

    // 作成した配列をもとに、gallery-checked, gallery-checked-{id} クラスを付与
    addClassGalleryChecked(galleryCheckedIds);
  });
  
  $(modalCloseBox).on('click', function() {
    closeModal();
  });
  
  $(modalClose).on('click', function() {
    closeModal();
  });
});

// ギャラリー画像がクリックされた時の処理
$(document).on('click', '.gallery', function() {
  const galleryId = $(this).data('gallery-id');

  if(galleryCheckedIds.includes(galleryId)) {
    galleryCheckedIds = galleryCheckedIds.filter(value => value !== galleryId); // 再代入
  } else {
    if(galleryCheckedIds.length < MAX_NUMBER_OF_GALLERIES) {
      galleryCheckedIds.push(galleryId);
    } else {
      alert('ギャラリー画像は' + MAX_NUMBER_OF_GALLERIES + '枚まで選択できます');
      return;
    }
  }
  removeClassGalleryChecked();
  addClassGalleryChecked(galleryCheckedIds);
});

// 全ての gallery-checked, gallery-checked-{id} クラスを削除
function removeClassGalleryChecked() {
  $('.gallery').removeClass(function(index, className) {
    return (className.match(/\bgallery-\S+/g) || []).join(' ');
  });
}

// 配列に存在するgallery_idに gallery-checked, gallery-checked-{id} クラスを付与
function addClassGalleryChecked(ids) {
  if(ids.length > 0) {
    $.each(ids, function( index, value ) {
      $('.gallery[data-gallery-id=' + value + ']').addClass('gallery-checked' + ' ' + 'gallery-checked-' + (index + 1));
    });
  }
}

// モーダルの選択するボタンがクリックされた時の処理
$(document).on('click', '#js-modal_submit', function() {
  $('.js-galleryBox').empty();
  if (galleryCheckedIds.length > 0) {
    $.each(galleryCheckedIds, function( index, value ) {
      const galleryChecked = $('.gallery[data-gallery-id=' + value + ']');
      const galleryImg = $(galleryChecked).find('img')[0];
      const gallerySrc = $(galleryImg).attr('src');

      $('.js-galleryBox-' + (index + 1)).append(
        '<img class="c-selectGalleryModal_container_img" src="' + gallerySrc + '">'
      );
    });

    const galleryCheckedIdsString = galleryCheckedIds.join(',');
    $('#js-hidden_field_gallery_ids').val(galleryCheckedIdsString);

    galleryCheckedIds = [];
    removeClassGalleryChecked();

    closeModal();
  } else {
    alert('画像が選択されていません');
    return;
  }
});

// TODO: 対象のモーダルを引数にして拡張するなど
function closeModal() {
  const modal = document.querySelector('.js-modal');
  $(modal).removeClass('c-selectGalleryModal_modal_open');
}

$(document).on('click', '.js-galleryBox', function() {
  alert("ギャラリー画像から選択ボタンで画像を追加・修正できます");
});
