// ------------------------------
// ヘッダー 3本線ボタンクリックでサイドバーの開閉処理
// ------------------------------
$(document).on('click', '.js-sidebarToggleBtn', function() {
  $(".js-sidebarToggleBtn").toggleClass("is-active");
  $(".js-sidebar").toggleClass("is-display");
  $(".js-sidebarBg").toggleClass("is-display");
});

// ------------------------------
// 半透明色の背景 クリックでサイドバーと共に閉じる処理
// ------------------------------
$(document).on('click', '.js-sidebarBg', function() {
  $(".js-sidebarToggleBtn").removeClass("is-active");
  $(".js-sidebar").removeClass("is-display");
  $(".js-sidebarBg").removeClass("is-display");
});

// TODO: 必要であればサイドバー展開時はスクロールを禁止する処理を追加する
