$(function() {
  $(document).on('click', '.js-accordion_open', function() {
    $(this).parent().find('.js-accordion').addClass('c-accordionMenu_accordion_open');
    $(this).parent().find('.js-accordion_background').addClass('c-accordionMenu_accordion_open');
  });

  $(document).on('click', '.js-accordion_background', function() {
    $(this).parent().find('.js-accordion').removeClass('c-accordionMenu_accordion_open');
    $(this).parent().find('.js-accordion_background').removeClass('c-accordionMenu_accordion_open');
  });

  $(document).on('click', '.js-menu', function() {
    $(this).parent().find('.js-accordion').removeClass('c-accordionMenu_accordion_open');
    $(this).parent().find('.js-accordion_background').removeClass('c-accordionMenu_accordion_open');
  });
});

// TODO: 対象のモーダルを引数にして拡張するなど
// function closeAccordion() {
//   const accordion = document.querySelector('.js-accordion');
//   const accordionBackground = document.querySelector('.js-accordion_background');

//   $(accordion).removeClass('c-accordionMenu_accordion_open');
//   $(accordionBackground).removeClass('c-accordionMenu_background_open');
// }
